/* eslint-disable no-undef */
import CryptoServices from '../../helper/CryptoServices'

function getLocalStorage() {
  try {
    return CryptoServices.Decrypt(localStorage.getItem(process.env.VUE_APP_STORAGE_NAME))
  } catch (error) {
    return ""
  }
}

function getToken() {
  const storage = getLocalStorage()
  var token = ""
  if (storage) {
    token = JSON.parse(storage)
  }
  return (storage) ? JSON.stringify(token.token) : ""
}

function getUser() {
  const storage = getLocalStorage()
  var user = ""
  if (storage) {
    user = JSON.parse(storage)
  }
  return (storage) ? JSON.stringify(user.user) : ""
}

export default {
  status: "",
  token: getToken(),
  user: getUser(),
};
