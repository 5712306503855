import axios from "axios";
import Crypto from "../../helper/CryptoServices";
export default {
  login({
    commit
  }, user) {
    return new Promise((resolve, reject) => {
      commit("REQUEST");

      const send = {
        email: user.username,
        password: user.password
      };

      axios({
          url: "v1/auth/login",
          data: send,
          method: "POST"
        })
        .then(response => {
          const resp = response.data.serve;
          const user = resp.user;
          const token = resp.access_token;
          const storage = {
            user: user,
            token: token,
          };
          const encryptStorage = Crypto.Encrypt(JSON.stringify(storage));
          localStorage.setItem(
            process.env.VUE_APP_STORAGE_NAME,
            encryptStorage
          );

          axios.defaults.headers.common["Authorization"] = "Bearer " + token;
          commit("LOGIN", storage);
          resolve(storage);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  logout({
    commit
  }) {
    return new Promise((resolve, reject) => {
      axios({
          url: "v1/auth/logout",
          method: "GET"
        })
        .then(response => {
          commit("LOGOUT");
          localStorage.removeItem(process.env.VUE_APP_STORAGE_NAME);
          delete axios.defaults.headers.common["Authorization"];
          resolve(response)
        })
        .catch(err => {
          reject(err)
        })
    })
  }
};