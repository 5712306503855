export default {
  REQUEST(state) {
    state.status = "loading";
  },
  LOGIN(state, payload) {
    state.status = "success";
    state.token = payload.token
    state.user = JSON.stringify(payload.user)
  },
  LOGOUT(state) {
    state.status = ""
    state.token = ""
    state.user = ""
  },
};