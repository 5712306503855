import Vue from "vue";
import App from "./App.vue";

// Vuesax Component Framework
import Vuesax from "vuesax";
import "material-icons/iconfont/material-icons.css"; //Material Icons
import "vuesax/dist/vuesax.css"; // Vuesax

Vue.use(Vuesax);

// Theme Configurations
import "../themeConfig.js";
// Globally Registered Components
import "./globalComponents.js";
// Styles: SCSS
import "./assets/scss/main.scss";

// Tailwind
import "@/assets/css/main.css";

// Vue Router
import router from "./router";

// Vuex Store
import store from "./store/store";

// PrismJS
import "prismjs";
import "prismjs/themes/prism.css";

//Crypto Services
import CryptoServices from "./helper/CryptoServices";

import VueClipboard from "vue-clipboard2";
Vue.use(VueClipboard);

// Axios
import Axios from "axios";

// Feather font icon
// eslint-disable-next-line no-undef
require("./assets/css/iconfont.css");

Vue.prototype.$http = Axios;
Vue.prototype.$crypto = CryptoServices;

// eslint-disable-next-line no-undef
Vue.prototype.$http.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT;
Vue.prototype.$http.defaults.headers.post["Content-Type"] = "application/json";

Vue.prototype.$http.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    if (error.response.status == 401) {
      localStorage.removeItem(process.env.VUE_APP_STORAGE_NAME);
      delete Axios.defaults.headers.common["Authorization"];
      window.location.reload(true);
    }
    return Promise.reject(error);
  }
);

function getLocalStorage() {
  try {
    return CryptoServices.Decrypt(
      // eslint-disable-next-line no-undef
      localStorage.getItem(process.env.VUE_APP_STORAGE_NAME)
    );
  } catch (error) {
    return "";
  }
}

const storage = getLocalStorage();
let token;
if (storage) {
  token = JSON.parse(storage);
}
if (token) {
  Vue.prototype.$http.defaults.headers.common["Authorization"] =
    "Bearer " + token.token;
}

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
