/* eslint-disable no-undef */

import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

import state from "./state"
import getters from "./getters"
import mutations from "./mutations"
import actions from "./actions"

import moduleAuth from "./auth/moduleAuth"

export default new Vuex.Store({
  getters,
  mutations,
  state,
  actions,
  modules: {
    auth: moduleAuth
  },
  strict: process.env.NODE_ENV !== 'development'
})
