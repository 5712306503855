/* eslint-disable no-undef */
/* 
  Object Strucutre:
    path => router path
    name => router name
    component(lazy loading) => component to load
*/

import Vue from "vue";
import Router from "vue-router";
import CryptoServices from "./helper/CryptoServices";
// Vuex Store
import store from "./store/store";

Vue.use(Router);

function storageValidation() {
  try {
    return !!CryptoServices.Decrypt(
      localStorage.getItem(process.env.VUE_APP_STORAGE_NAME)
    );
  } catch (error) {
    return false;
  }
}

function guardedAuth(to, from, next) {
  const storage = storageValidation();
  if ((!store.getters["auth/isLoggedIn"] || !storage) && to.path !== "/login") {
    next("/login");
  } else {
    next();
  }
}

function loggedIn(to, from, next) {
  const storage = storageValidation();
  if (!store.getters["auth/isLoggedIn"] || !storage) {
    next();
  } else {
    next(from);
  }
}

let lang = "en";
if (localStorage.getItem("lang")) {
  lang = localStorage.getItem("lang");
}
export default new Router({
  base: process.env.BASE_URL,
  mode: "history",
  hash: false,
  routes: [
    {
      // =============================================================================
      // MAIN LAYOUT ROUTES
      // =============================================================================
      path: "",
      component: () => import("./layouts/main/Main.vue"),
      beforeEnter: guardedAuth,
      children: [
        // =============================================================================
        // Theme Routes
        // =============================================================================
        {
          path: "/",
          name: "home",
          component: () => import("@/views/pages/Home.vue"),
        },
        {
          path: "/meta",
          name: "meta",
          component: () =>
            lang === "en"
              ? import("@/views/pages/master/pages/en/meta/meta/index.vue")
              : import("@/views/pages/master/pages/id/meta/meta/index.vue"),
        },
        {
          path: "/add-meta",
          name: "add-meta",
          component: () =>
            lang === "en"
              ? import("@/views/pages/master/pages/en/meta/meta/store.vue")
              : import("@/views/pages/master/pages/id/meta/meta/store.vue"),
        },
        {
          path: "/detail-meta/:id",
          props: true,
          name: "detail-meta",
          component: () =>
            lang === "en"
              ? import("@/views/pages/master/pages/en/meta/meta/detail.vue")
              : import("@/views/pages/master/pages/id/meta/meta/detail.vue"),
        },

        {
          path: "/review",
          name: "review",
          component: () =>
            lang === "en"
              ? import("@/views/pages/master/pages/en/review/index.vue")
              : import("@/views/pages/master/pages/id/review/index.vue"),
        },
        {
          path: "/add-review",
          name: "add-review",
          component: () =>
            lang === "en"
              ? import("@/views/pages/master/pages/en/review/store.vue")
              : import("@/views/pages/master/pages/id/review/store.vue"),
        },
        {
          path: "/detail-review/:id",
          props: true,
          name: "detail-review",
          component: () =>
            lang === "en"
              ? import("@/views/pages/master/pages/en/review/detail.vue")
              : import("@/views/pages/master/pages/id/review/detail.vue"),
        },

        {
          path: "/home-header",
          name: "home-header",
          component: () =>
            lang === "en"
              ? import("@/views/pages/master/pages/en/home/header/index.vue")
              : import("@/views/pages/master/pages/id/home/header/index.vue"),
        },
        {
          path: "/add-home-header",
          name: "add-home-header",
          component: () =>
            lang === "en"
              ? import("@/views/pages/master/pages/en/home/header/store.vue")
              : import("@/views/pages/master/pages/id/home/header/store.vue"),
        },
        {
          path: "/detail-home-header/:id",
          props: true,
          name: "detail-home-header",
          component: () =>
            lang === "en"
              ? import("@/views/pages/master/pages/en/home/header/detail.vue")
              : import("@/views/pages/master/pages/id/home/header/detail.vue"),
        },

        {
          path: "/home-section-first",
          name: "home-section-first",
          component: () =>
            lang === "en"
              ? import("@/views/pages/master/pages/en/home/section-1/index.vue")
              : import(
                  "@/views/pages/master/pages/id/home/section-1/index.vue"
                ),
        },
        {
          path: "/add-home-section-first",
          name: "add-home-section-first",
          component: () =>
            lang === "en"
              ? import("@/views/pages/master/pages/en/home/section-1/store.vue")
              : import(
                  "@/views/pages/master/pages/id/home/section-1/store.vue"
                ),
        },
        {
          path: "/detail-home-section-first/:id",
          props: true,
          name: "detail-home-section-first",
          component: () =>
            lang === "en"
              ? import(
                  "@/views/pages/master/pages/en/home/section-1/detail.vue"
                )
              : import(
                  "@/views/pages/master/pages/id/home/section-1/detail.vue"
                ),
        },

        {
          path: "/home-section-second",
          name: "home-section-second",
          component: () =>
            lang === "en"
              ? import("@/views/pages/master/pages/en/home/section-2/index.vue")
              : import(
                  "@/views/pages/master/pages/id/home/section-2/index.vue"
                ),
        },
        {
          path: "/add-home-section-second",
          name: "add-home-section-second",
          component: () =>
            lang === "en"
              ? import("@/views/pages/master/pages/en/home/section-2/store.vue")
              : import(
                  "@/views/pages/master/pages/id/home/section-2/store.vue"
                ),
        },
        {
          path: "/detail-home-section-second/:id",
          props: true,
          name: "detail-home-section-second",
          component: () =>
            lang === "en"
              ? import(
                  "@/views/pages/master/pages/en/home/section-2/detail.vue"
                )
              : import(
                  "@/views/pages/master/pages/id/home/section-2/detail.vue"
                ),
        },

        {
          path: "/home-section-third",
          name: "home-section-third",
          component: () =>
            lang === "en"
              ? import("@/views/pages/master/pages/en/home/section-3/index.vue")
              : import(
                  "@/views/pages/master/pages/id/home/section-3/index.vue"
                ),
        },
        {
          path: "/add-home-section-third",
          name: "add-home-section-third",
          component: () =>
            lang === "en"
              ? import("@/views/pages/master/pages/en/home/section-3/store.vue")
              : import(
                  "@/views/pages/master/pages/id/home/section-3/store.vue"
                ),
        },
        {
          path: "/detail-home-section-third/:id",
          props: true,
          name: "detail-home-section-third",
          component: () =>
            lang === "en"
              ? import(
                  "@/views/pages/master/pages/en/home/section-3/detail.vue"
                )
              : import(
                  "@/views/pages/master/pages/id/home/section-3/detail.vue"
                ),
        },

        {
          path: "/tours1",
          name: "tours",
          component: () =>
            lang === "en"
              ? import("@/views/pages/master/pages/en/tours/tours/index.vue")
              : import("@/views/pages/master/pages/id/tours/tours/index.vue"),
        },
        {
          path: "/add-tours1",
          name: "add-tours",
          component: () =>
            lang === "en"
              ? import("@/views/pages/master/pages/en/tours/tours/store.vue")
              : import("@/views/pages/master/pages/id/tours/tours/store.vue"),
        },
        {
          path: "/detail-tours1/:id",
          props: true,
          name: "detail-tours",
          component: () =>
            lang === "en"
              ? import("@/views/pages/master/pages/en/tours/tours/detail.vue")
              : import("@/views/pages/master/pages/id/tours/tours/detail.vue"),
        },

        {
          path: "/tours2",
          name: "tours-detail",
          component: () =>
            lang === "en"
              ? import(
                  "@/views/pages/master/pages/en/tours/tours-detail/index.vue"
                )
              : import(
                  "@/views/pages/master/pages/id/tours/tours-detail/index.vue"
                ),
        },
        {
          path: "/add-tours2",
          name: "add-tours-detail",
          component: () =>
            lang === "en"
              ? import(
                  "@/views/pages/master/pages/en/tours/tours-detail/store.vue"
                )
              : import(
                  "@/views/pages/master/pages/id/tours/tours-detail/store.vue"
                ),
        },
        {
          path: "/detail-tours2/:id",
          props: true,
          name: "detail-tours-detail",
          component: () =>
            lang === "en"
              ? import(
                  "@/views/pages/master/pages/en/tours/tours-detail/detail.vue"
                )
              : import(
                  "@/views/pages/master/pages/id/tours/tours-detail/detail.vue"
                ),
        },

        {
          path: "/tours3",
          name: "tours-itinerary",
          component: () =>
            lang === "en"
              ? import(
                  "@/views/pages/master/pages/en/tours/tours-itinerary/index.vue"
                )
              : import(
                  "@/views/pages/master/pages/id/tours/tours-itinerary/index.vue"
                ),
        },
        {
          path: "/add-tours3",
          name: "add-tours-itinerary",
          component: () =>
            lang === "en"
              ? import(
                  "@/views/pages/master/pages/en/tours/tours-itinerary/store.vue"
                )
              : import(
                  "@/views/pages/master/pages/id/tours/tours-itinerary/store.vue"
                ),
        },
        {
          path: "/detail-tours3/:id",
          props: true,
          name: "detail-tours-itinerary",
          component: () =>
            lang === "en"
              ? import(
                  "@/views/pages/master/pages/en/tours/tours-itinerary/detail.vue"
                )
              : import(
                  "@/views/pages/master/pages/id/tours/tours-itinerary/detail.vue"
                ),
        },

        {
          path: "/story-header",
          name: "story-header",
          component: () =>
            lang === "en"
              ? import("@/views/pages/master/pages/en/story/header/index.vue")
              : import("@/views/pages/master/pages/id/story/header/index.vue"),
        },
        {
          path: "/add-story-header",
          name: "add-story-header",
          component: () =>
            lang === "en"
              ? import("@/views/pages/master/pages/en/story/header/store.vue")
              : import("@/views/pages/master/pages/id/story/header/store.vue"),
        },
        {
          path: "/detail-story-header/:id",
          props: true,
          name: "detail-story-header",
          component: () =>
            lang === "en"
              ? import("@/views/pages/master/pages/en/story/header/detail.vue")
              : import("@/views/pages/master/pages/id/story/header/detail.vue"),
        },

        {
          path: "/story1",
          name: "story",
          component: () =>
            lang === "en"
              ? import("@/views/pages/master/pages/en/story/story/index.vue")
              : import("@/views/pages/master/pages/id/story/story/index.vue"),
        },
        {
          path: "/add-story",
          name: "add-story",
          component: () =>
            lang === "en"
              ? import("@/views/pages/master/pages/en/story/story/store.vue")
              : import("@/views/pages/master/pages/id/story/story/store.vue"),
        },
        {
          path: "/detail-story/:id",
          props: true,
          name: "detail-story",
          component: () =>
            lang === "en"
              ? import("@/views/pages/master/pages/en/story/story/detail.vue")
              : import("@/views/pages/master/pages/id/story/story/detail.vue"),
        },

        {
          path: "/tips",
          name: "tips",
          component: () =>
            lang === "en"
              ? import("@/views/pages/master/pages/en/tips/tips/index.vue")
              : import("@/views/pages/master/pages/id/tips/tips/index.vue"),
        },
        {
          path: "/add-tips",
          name: "add-tips",
          component: () =>
            lang === "en"
              ? import("@/views/pages/master/pages/en/tips/tips/store.vue")
              : import("@/views/pages/master/pages/id/tips/tips/store.vue"),
        },
        {
          path: "/detail-tips/:id",
          props: true,
          name: "detail-tips",
          component: () =>
            lang === "en"
              ? import("@/views/pages/master/pages/en/tips/tips/detail.vue")
              : import("@/views/pages/master/pages/id/tips/tips/detail.vue"),
        },

        {
          path: "/team",
          name: "team",
          component: () =>
            lang === "en"
              ? import("@/views/pages/master/pages/en/story/team/index.vue")
              : import("@/views/pages/master/pages/id/story/team/index.vue"),
        },
        {
          path: "/add-team",
          name: "add-team",
          component: () =>
            lang === "en"
              ? import("@/views/pages/master/pages/en/story/team/store.vue")
              : import("@/views/pages/master/pages/id/story/team/store.vue"),
        },
        {
          path: "/detail-team/:id",
          props: true,
          name: "detail-team",
          component: () =>
            lang === "en"
              ? import("@/views/pages/master/pages/en/story/team/detail.vue")
              : import("@/views/pages/master/pages/id/story/team/detail.vue"),
        },

        {
          path: "/blog",
          name: "blog",
          component: () =>
            lang === "en"
              ? import("@/views/pages/master/pages/en/blog/blog/index.vue")
              : import("@/views/pages/master/pages/id/blog/blog/index.vue"),
        },
        {
          path: "/add-blog",
          name: "add-blog",
          component: () =>
            lang === "en"
              ? import("@/views/pages/master/pages/en/blog/blog/store.vue")
              : import("@/views/pages/master/pages/id/blog/blog/store.vue"),
        },
        {
          path: "/detail-blog/:id",
          props: true,
          name: "detail-blog",
          component: () =>
            lang === "en"
              ? import("@/views/pages/master/pages/en/blog/blog/detail.vue")
              : import("@/views/pages/master/pages/id/blog/blog/detail.vue"),
        },
        {
          path: "/website",
          props: true,
          name: "website",
          component: () => import("@/views/pages/Setting.vue"),
        },
        {
          path: "/contact",
          props: true,
          name: "contact",
          component: () => import("@/views/pages/Contact.vue"),
        },
        {
          path: "/detail-contact/:id",
          props: true,
          name: "detail-contact",
          component: () => import("@/views/pages/DetailContact.vue"),
        },
        {
          path: "/password",
          name: "password",
          component: () => import("@/views/pages/Password"),
        },
        {
          path: "/payment",
          props: true,
          name: "payment",
          // component: () => import("@/views/pages/Payment.vue")
          component: () =>
            lang === "en"
              ? import("@/views/pages/master/pages/en/payment/payment.vue")
              : import("@/views/pages/master/pages/id/payment/payment.vue"),
        },
        {
          path: "/add-reservation",
          name: "add-reservation",
          // component: () => import("@/views/pages/master/pages/en/payment/store.vue")
          component: () =>
            lang === "en"
              ? import("@/views/pages/master/pages/en/payment/store.vue")
              : import("@/views/pages/master/pages/id/payment/store.vue"),
        },
        {
          path: "/detail-payment/:id",
          props: true,
          name: "detail-payment",
          // component: () => import("@/views/pages/DetailPayment.vue")
          component: () =>
            lang === "en"
              ? import(
                  "@/views/pages/master/pages/en/payment/detailpayment.vue"
                )
              : import(
                  "@/views/pages/master/pages/id/payment/detailpayment.vue"
                ),
        },
      ],
    },
    // ===================================================================x==========
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
      path: "",
      component: () => import("@/layouts/full-page/FullPage.vue"),
      children: [
        // =============================================================================
        // PAGES
        // =============================================================================
        {
          path: "/login",
          name: "pagelogin",
          beforeEnter: loggedIn,
          component: () => import("@/views/pages/Login.vue"),
        },
        {
          path: "pages/error-404",
          name: "pageError404",
          component: () => import("@/views/pages/Error404.vue"),
        },
      ],
    },
    // Redirect to 404 page, if no match found
    {
      path: "*",
      redirect: "/pages/error-404",
    },
  ],
});
